import axios from "axios";
import { checkTokenRefresh, getHttpStatus } from "../helper/refreshToken";

const error_code = {
	500: "Server Error - Something went wrong",
	404: "404 - ",
	401: "401 - User session expired, Trying to refresh",
};

const reqconig = (accesstoken) => {
	let config = {};
	config = accesstoken
		? {
				...config,
				headers: {
					Authorization: `Bearer ${accesstoken}`,
				},
		  }
		: {};

	return config;
};

export const POST = async (url, data, callback, accesstoken = false) => {
	try {
		await axios
			.post(url, data, reqconig(accesstoken))
			.then((res) => {
				callback(res.data);
			})
			.catch(async (err) => {
				await checkTokenRefresh(err.response && err.response.status, url);
				callback({
					status: 0,
					http_error: getHttpStatus(err.response.status),
					// code: err.code,
					error: error_code[err && err.response && err.response.status] || "Something went wrong", // need to get code from responce
				});
			});
	} catch (e) {}
};

export const GET = (url, callback, accesstoken = false) => {
	try {
		axios
			.get(url, reqconig(accesstoken))
			.then((res) => {
				callback(res.data);
			})
			.catch(async (err) => {
				if (err.response && err.response.status === 302) {
					callback({
						status: err.response && err.response.status === 302 ? 302 : 0,
						http_error: true,
						code: err.code,
						error: error_code[err && err.response && err.response.status] || "Something went wrong", // need to get code from responce
					});
				} else {
					await checkTokenRefresh(err.response && err.response.status, url);
				}
			});
	} catch (e) {}
};
export const PUT = (url, data, callback, accesstoken = false) => {
	try {
		axios
			.put(url, data, reqconig(accesstoken))
			.then((res) => {
				callback(res.data);
			})
			.catch(async (err) => {
				await checkTokenRefresh(err.response && err.response.status, url);
				callback({
					status: 0,
					http_error: getHttpStatus(err.response.status),
					// code: err.code,
					error: error_code[err.code] || "Something went wrong", // need to get code from responce
				});
			});
	} catch (e) {}
};
export const DELETE = (url, callback, accesstoken) => {
	try {
		axios
			.delete(url, reqconig(accesstoken))
			.then((res) => {
				callback(res.data);
			})
			.catch(async (err) => {
				await checkTokenRefresh(err.response && err.response.status, url);
				callback({
					status: 0,
					http_error: getHttpStatus(err.response.status),
					// code: err.code,
					error: error_code[err.response.status] || "Something went wrong", // need to get code from responce
				});
			});
	} catch (e) {}
};
