import { POST, GET } from "../api";
import { CLIENT_SIGNIN, CLIENT_PROFILE } from "../../constants/api";
import { USER, USER_PROFILE } from "../../constants/local-storage";
import { SOMETHING_WRONG, IN_ACTIVE_USER } from "../../lang/en/messages";
import { asyncLocalStorage } from "../../utils";
import { ACTIVE } from "../../constants/app";
import store from "../../store";
import { setIsGuestUser, userLogin, userProfile } from "../../actions";
import { GA_login } from "../../components/features/googleAnalytics/index";

export const signin = (data, toast, callback) => {
	const isGuestUser = store.getState().GuestUser.isGuestUser;

	// Get GuestId from local storage and convert it to a number
	const guestId = Number(localStorage.getItem("GuestId")) || ""; // Convert guestId to a number

	// Add new keys to data object
	const modifiedData = {
		...data,
		isGuest: isGuestUser, // Add isGuestUser from Redux store
		guestId, // Add guestId from local storage, now numeric
	};
	POST(CLIENT_SIGNIN, modifiedData, function (data) {
		if (data.status && data.status) {
			GET(
				CLIENT_PROFILE,
				(profile) => {
					const status = profile.status && profile.data && profile.data.status;
					if (status === ACTIVE) {
						asyncLocalStorage.setItem(USER, JSON.stringify(data.data)).then(() => {
							store.dispatch(userLogin());
							GA_login();
							toast.success("Logged in successfully");
							callback(data);
							localStorage.setItem("isGuestUser", false);
							localStorage.removeItem("GuestToken");
							localStorage.removeItem("GuestId");

							store.dispatch(setIsGuestUser(false));
							// toast.success(data.message || ""); // not let it break
						});
						asyncLocalStorage.setItem(USER_PROFILE, JSON.stringify(profile.data)).then(() => {
							store.dispatch(userProfile());
						});
						// let history = useHistory();
						// history.push("/");
					} else {
						toast.error(IN_ACTIVE_USER);
					}
				},
				data.data.auth && data.data.auth.access_token
			);
		} else {
			if (data.http_error) {
				callback(false);
				toast.error(data.error);
			} else if (parseInt(data.code) === 403) {
				callback(data);
			} else {
				if (data.errors) {
					callback(false);
					for (let i = 0; i < data.errors.length; i++) {
						toast.error(data.errors[i]);
					}
				} else {
					callback(false);
					toast.error(SOMETHING_WRONG);
				}
			}
		}
	});
};
